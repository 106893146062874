<template>
  <div>
    <v-navigation-drawer
      permanent
      app
      :width="300"
    >
      <template v-slot:prepend>
        <v-list-item class="primary" dark>
          <v-list-item-title>Orden Nueva</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </template>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-list nav>
          <v-subheader>Datos de la orden de distribucion</v-subheader>
          <v-list-item>
            <v-text-field
              v-if="!clienteEdited"
              prepend-icon="mdi-account"
              label="Cliente"
              readonly
            ></v-text-field>
            <v-text-field
              v-else
              :value="clienteEdited"
              prepend-icon="mdi-account-star"
              label="Cliente"
              readonly
            ></v-text-field>
          </v-list-item>
          <v-list-item>
            <v-text-field
              v-if="!domicilioEdited"
              prepend-icon="mdi-map-marker"
              label="Domicilio"
              readonly
            ></v-text-field>
            <v-text-field
              v-else
              :value="domicilioEdited.descripcion"
              prepend-icon="mdi-map-marker"
              label="Domicilio"
              readonly
            ></v-text-field>
          </v-list-item>
          <v-list-item>
            <my-date-picker
              v-model="published"
              label="Fecha de publicación"
              min-today
            ></my-date-picker>
          </v-list-item>
          <v-list-item>
            <my-chofer-select
              v-model="chofer"
              :rules="rules.chofer_id"
              clearable
            ></my-chofer-select>
          </v-list-item>
          <v-list-item>
            <v-text-field
              v-model="advertencia"
              placeholder="Ingrese una advertencia"
              label="Advertencia"
              prepend-icon="mdi-alert"
              color="primary"
              clearable
            ></v-text-field>
          </v-list-item>
          <v-list-item
            @click="onSubmit"
            :class="valid ? 'primary' : 'gray'"
            dark
          >
            <v-list-item-avatar>F2</v-list-item-avatar>
            <v-list-item-title>Confirmar</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-check</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-form>
      <v-list nav>
        <v-divider></v-divider>
        <v-list-item
          @click="onCancel()"
        >
          <v-list-item-avatar>F1</v-list-item-avatar>
          <v-list-item-title>Volver</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-table-star</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-sheet>
      <v-container
        v-if="domicilioIsSelected"
      >
        <my-domicilio-edit-onlive
          v-model="domicilio"
        ></my-domicilio-edit-onlive>
      </v-container>
      <v-container
        v-else
      >
        <my-domicilio-create-onlive
          v-model="domicilio"
        ></my-domicilio-create-onlive>
      </v-container>
    </v-sheet>
    <v-divider></v-divider>
    <v-sheet>
      <v-container>
        <my-domicilio-select
          v-model="domicilio"
          :rules="rules.domicilio_id"
        ></my-domicilio-select>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DomicilioSelect from '@/pages/domicilios/SelectOrdenes'
import DomicilioCreateOnLive from '@/pages/Domicilios/CreateOnOrdenes'
import DomicilioEditOnLive from '@/pages/Domicilios/EditOnOrdenes'
import ChoferSelect from '@/pages/choferes/Select'
import DatePicker from '@/components/DatePicker'
export default {
  components: {
    'my-domicilio-select': DomicilioSelect,
    'my-domicilio-create-onlive': DomicilioCreateOnLive,
    'my-domicilio-edit-onlive': DomicilioEditOnLive,
    'my-chofer-select': ChoferSelect,
    'my-date-picker': DatePicker,
  },
  data () {
    return {
      domicilioAux: null,
      choferAux: null,
      tab: null,
      valid: true,
      panel: {
        showCliente: true,
        showDomicilio: true,
      },
      resetValidation: false
    }
  },
  beforeMount() {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy() {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  mounted () {
    this.resetEdited()
  },
  computed: {
    ...mapGetters({
      edited: 'Ordenes/edited',
      rules: 'Ordenes/rules',
      loading: 'Ordenes/loading',
      domicilioEdited: 'DomiciliosFindOrCreate/edited',
      domicilioIsSelected: 'DomiciliosFindOrCreate/isSelected',
    }),
    clienteEdited () {
      return this.domicilioEdited.cliente
    },
    clienteId () {
      return this.domicilioEdited && this.domicilioEdited.cliente_id ? this.domicilioEdited.cliente_id : ''
    },
    advertencia: {
      get () {
        return this.edited.advertencia
      },

      set (value) {
        this.setAdvertencia(value)
      }
    },
    domicilio: {
      get () {
        return this.edited.domicilio_id
      },
      set (value) {
        // this.domicilioAux = value
        this.setDomicilioId(value)
      }
    },
    chofer: {
      get () {
        return this.edited.chofer_id
      },
      set (value) {
        this.setChoferId(value)
      }
    },
    published: {
      get () {
        return this.edited.published_at
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          published_at: value
        })
      }
    }
  },
  watch: {
    //
  },
  methods: {
    ...mapActions({
      onSave: 'Ordenes/onSave',
      onResetEdited: 'Ordenes/onResetEdited',
    }),
    ...mapActions('DomiciliosFindOrCreate', ['onClearSelected', 'onClearEdited']),
    ...mapMutations({
      setEdited: 'Ordenes/setEdited',
      setId: 'Ordenes/setId',
      setChoferId: 'Ordenes/setChoferId',
      setDomicilioId: 'Ordenes/setDomicilioId',
      setEstadoId: 'Ordenes/setEstadoId',
      setPublishedAt: 'Ordenes/setPublishedAt',
      setAdvertencia: 'Ordenes/setAdvertencia',
      setObservaciones: 'Ordenes/setObservaciones'
    }),
    onCancel () {
      this.resetEdited()
      this.$router.go(-1)
    },
    onSubmit (event) {
      event.preventDefault()
      this.$refs.form.validate()
      if (this.valid && this.domicilio) {
        this.onSave()
          .then(response => {
            this.resetEdited()
          })
          .catch(error => {
            alert(JSON.stringify(error))
          })
      }
      else if (!this.domicilio) {
        alert('No ha seleccionado un cliente')
      }
    },
    resetEdited () {
      this.onResetEdited()
      this.onClearEdited()
      this.onClearSelected()
      this.setEdited({
        ...this.edited,
        published_at: this.$moment().format('YYYY-MM-DD')
      })
      this.resetValidation = true
    },
    handleKeyboard(event) {
      if (!this.loading) {
        if(event.key === 'F1') {
          event.preventDefault()
          this.$router.push({ name: 'ordenes-datatable', params: { id: this.chofer } })
        } else if(event.key === 'F2') {
          this.onSubmit(event)
        }
      }
    },
    onToggleShowCliente (event) {
      this.panel.showCliente = !this.panel.showCliente
      event.preventDefault()
    },
    onToggleShowDomicilio (event) {
      this.panel.showDomicilio = !this.panel.showDomicilio
      event.preventDefault()
    },
  },
}
</script>
